<template>
  <div>
    <vx-card class="mb-4">
      <vs-tabs v-model="activeTab">
        <vs-tab :label="$t('questionnaire_corrector.config')">
          <questionnaire-corrector-config :id="id"></questionnaire-corrector-config>
        </vs-tab>
        <vs-tab :label="$t('questionnaire_corrector.teachers')" v-if="isEdit()">
          <questionnaire-corrector-teacher :id="id"></questionnaire-corrector-teacher>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'

import QuestionnaireCorrectorService from '@/services/api/QuestionnaireCorrectorService'
import QuestionnaireCorrectorConfig from './QuestionnaireCorrectorConfig.vue'
import QuestionnaireCorrectorTeacher from './QuestionnaireCorrectorTeacher.vue'

export default {
  directives: {

  },
  components: {
    QuestionnaireCorrectorConfig,
    QuestionnaireCorrectorTeacher
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: null,
    activeTab: 0
  }),
  computed: {

  },
  beforeMount() {

  },
  mounted() {
    this.service = QuestionnaireCorrectorService.build(this.$vs)
  },

  methods: {
    cancel() {
      this.$router.push('/questionnaire_correctors')
    },
    isAdmin() {
      return isAdmin()
    },
    isEdit(){
      return !_.isNil(this.id)
    },
  },
  beforeDestroy() {
    this.destroyChilds(this)
  }
}
</script>

<style>

</style>
