<template>
  <div>
    <div class="vx-row mt-2">
      <div class="vx-col sm:w-1/1 w-full mb-2">
        <div class="w-full">
          <select-suggestion
            :max="20"
            :label="$t('Questionnaire')"
            :class="questionnaire ? 'flex-grow' : 'flex-grow required'"
            column="name,description"
            model="ContentQuestionnaire"
            v-model="questionnaire"
            :appendClearOption="true"
            ref="select_questionnaire"
            placeholderText="Digite o nome do questionário"/>
          <span
              class="text-danger text-sm"
              v-show="errors.has('questionnaire_id')">{{ errors.first('questionnaire_id') }}</span>
        </div>
        <div>
            <vs-input
              lang="pt-BR"
              type="number"
              v-validate="'required|numeric'"
              :label="$t('questionnaire_corrector.min_score')"
              v-model="model.min_score"/>
        </div>
        <div class="w-full mt-2">
          <vs-textarea
            id="description"
            :label="$t('questionnaire_corrector.description')"
            v-model="model.description" />
          <span class="text-danger text-sm" v-show="errors.has('description')">{{errors.first('description')}}</span>
        </div>
      </div>
    </div>
    <div v-if="isEdit()">
      <!-- CRITERIA-->
      <div class="vx-row flex flex-row mb-2">
        <div class="w-full pb-4"> <label class="block text-left font-medium">{{ $t('questionnaire_corrector.evaluation.title') }}</label><hr class="border-faint-grey"></div>
        <div class="w-full flex mb-2 ml-4">
          <label :class="allCriteria ? 'text-primary' : ''">{{ $t('questionnaire_corrector.criteria-all-questions') }}</label>
          <vs-switch class="bg-primary mr-2 ml-2" v-model="allCriteria" @input="updateGrid"/>
          <label :class="allCriteria ? '' : 'text-primary'">{{ $t('questionnaire_corrector.criteria-different-questions') }}</label>
        </div>
      </div>
      <!-- Criteria for ALL -->
      <div class="grid grid-cols-1 gap-y-2" v-if="allCriteria">
        <div v-for="(criteria, index) in criteriaList" :key="index">
          <correction-criteria :model="criteria"
                              :index="index"
                              :showButtonDelete="true"
                              @deleteCriteria="deleteCriteria"
                              @validateWeight="validateMaxWeight">
          </correction-criteria>
        </div>
        <div>
          <div style="margin-left: -10px;">
            <vs-divider class="mt-4" position="left">{{ $t('questionnaire_corrector.add-criteria') }}</vs-divider>
          </div>
          <correction-criteria :showIndex="false"
                                  :showButtonAdd="true"
                                  @addCriteria="addCriteria"
                                  @complexEditorOpened="editorTextIsOpen"
                                  @validateWeight="validateMaxWeight">
          </correction-criteria>
        </div>
      </div>
      <!-- Criteria FOR QUESTION -->
      <div  class="grid grid-cols-1 gap-y-2">
        <vs-collapse accordion type="border" v-if="!allCriteria">
          <vs-collapse-item v-for="(question, index) in questions" :key="`question_${question.id}`" :ref="`collapse_${question.id}`">
            <div slot="header">
              <div class="grid grid-cols-2 mb-2">
                <div class="flex justify-start">
                  <b>{{ $t('questao-this-index', [index+1]) }}</b>
                </div>
                <div class="flex justify-end">
                  <b>{{ $t('pontuacao-maxima') }} : {{formatScore(question.data.score)}}</b>
                </div>
              </div>
              <div class="editor-content ck-content content-break-word ml-3 mr-20">{{shortTitle(question.data.title, 200)}}</div>
            </div>
            <div class="grid grid-cols-1 gap-y-1" :key="keyToForceRepaint">
              <div v-for="(qCriteria, cIndex) in question.criteriaList" :key="`cIndex_${index}_${cIndex}`">
                  <correction-criteria :model="qCriteria"
                                      :questionId="qCriteria.question_id"
                                      :index="cIndex"
                                      :showButtonDelete="true"
                                      @deleteCriteria="deleteCriteria"
                                      @complexEditorOpened="editorTextIsOpen"
                                      @validateWeight="validateMaxWeight"></correction-criteria>
              </div>
            </div>
            <div>
              <div style="margin-left: -10px;">
                <vs-divider class="mt-4" position="left">{{ $t('questionnaire_corrector.add-criteria') }}</vs-divider>
              </div>
              <div>
              <correction-criteria :showIndex="false"
                                  :showButtonAdd="true"
                                  :questionId="question.id"
                                  @addCriteria="addCriteria"
                                  @complexEditorOpened="editorTextIsOpen"
                                  @validateWeight="validateMaxWeight"></correction-criteria>
              </div>
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </div>
    <!-- row in action-->
    <div class="vx-row mb-2 mt-8">
      <div class="w-full flex gap-2 justify-end">
        <vs-button
          v-if="!isEdit()"
          :disabled="!validateForm"
          v-permission="'questionnaire_correctors.create'"
          @click="createOrUpdate">
          {{ $t('action.save') }}
        </vs-button>
        <vs-button
          v-if="isEdit()"
          :disabled="!validateForm"
          v-permission="'questionnaire_correctors.edit'"
          @click="createOrUpdate">
          {{ $t('action.save') }}
        </vs-button>
        <vs-button
          type="border"
          @click="goBack">
          {{ $t('common.back') }}
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SelectSuggestion from '@/components/SelectSuggestion.vue'
import CorrectionCriteria from './CorrectionCriteria.vue'

import QuestionnaireCorrectorService from '@/services/api/QuestionnaireCorrectorService'

export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectSuggestion,
    CorrectionCriteria
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: null,
    allCriteria: true,
    maxWeight: 100,
    model: {
      id: null,
      description: ''
    },
    questionnaire: null,
    keyToForceRepaint: 0, //To rapaint (forceRepaint)
    criteriaList: [], //By All question
    questions : [],
    questionCriteriaList : [], //By question
  }),
  computed: {
    validateForm() {
      return !this.isEmpty(_.get(this.questionnaire, 'id'))
    }
  },
  beforeMount() {
    this.service = QuestionnaireCorrectorService.build(this.$vs)
  },
  mounted() {
    if (this.id) {
      this.loadData(this.id)
    }
  },
  methods: {
    loadData(id) {
      this.$vs.loading()
      const promisses = []

      //Config
      promisses.push(
        this.service.read(id).then(
          response => {
            this.model = response
            this.questionnaire = {id : response.questionnaire_id, name: response.questionnaire_name}
            this.allCriteria = response.data.criteria_for_all
            this.criteriaConfig(response.data)
          }
        ))

      //Questions
      promisses.push(
        this.service.findQuestions(id).then(
          response => {
            this.questions = response
          })
      )

      //
      Promise.all(promisses).then(() => {
        this.$vs.loading.close()
        console.log('all load...')
        this.processCriteriaInQuestion()
      })
    },
    createOrUpdate() {
      this.$vs.loading()

      //Only selection. Clear trash
      if (this.allCriteria) {
        this.questionCriteriaList = []
      } else {
        this.criteriaList = []
        this.questionCriteriaList = []
        //Get arrays from questions
        this.questions.forEach((question, index) => {
          question.criteriaList.forEach((criteria, cIndex) => {
            this.questionCriteriaList.push(criteria)
          })//End questionCriteriaList criteriaList
        })//End forEach questions

      }
      const modelData = {
        id: this.model.id,
        description: this.model.description,
        min_score: this.model.min_score,
        questionnaire_id: this.questionnaire.id,

        data: {
          criteria_for_all: this.allCriteria,
          criteria : this.allCriteria ? this.criteriaList : this.questionCriteriaList
        }
      }

      this.service.createOrUpdate(modelData).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          this.$router.push(`/questionnaire_correctors/${data.id}/edit`)
        },
        error => {
          //this.showErrors(this.$validator, error)
          this.notifyError(
            this.$vs,
            this.$t('nao-foi-possivel-realizar-esta-operacao')
          )
        }
      ).finally(
        () => { this.$vs.loading.close() }
      )
    },
    goBack() {
      this.$router.push('/questionnaire_correctors')
    },
    isAdmin() {
      return isAdmin()
    },
    isEdit() {
      return !_.isNil(this.id)
    },
    criteriaConfig(criteriaData) {
      const criteriaList = criteriaData.criteria
      if (criteriaData.criteria_for_all) {
        this.criteriaList = criteriaList
      } else {
        this.questionCriteriaList = criteriaList
      }
    },
    processCriteriaInQuestion() {
      if (this.questions) {
        this.questions.forEach((question, index) => {
          if (question.criteriaList === undefined) {
            question.criteriaList = []
          }
          this.questionCriteriaList.forEach((criteria, cIndex) => {
            if (question.id === criteria.question_id) {
              question.criteriaList.push(criteria)
              this.forceRepaintCriteriaByQuestion(question.id)
            }
          })//End questionCriteriaList criteriaList
        })//End forEach questions
      }
    },
    addCriteria(questionId, criteriaModel) {
      const criteria = this._.cloneDeep(criteriaModel)
      if (questionId) { //By Question
        this.addCriteriaForQuestion(questionId, criteria)
        this.clearFormCriteria(criteriaModel)
        this.forceRepaintCriteriaByQuestion(questionId)
      } else { //By all Criteria
        this.maxWeight = (this.maxWeight - criteriaModel.weight)
        this.criteriaList.push(criteria)
        this.clearFormCriteria(criteriaModel)
      }
      this.scrollToBotton()
    },
    deleteCriteria(questionId, index) {
      if (questionId) { //By Question
        const question = this.questions.find(q => (q.id === questionId))
        question.criteriaList.splice(index, 1)
        this.forceRepaintCriteriaByQuestion(questionId)
      } else { //By all Criteria
        this.criteriaList.splice(index, 1)
      }
    },
    addCriteriaForQuestion(questionId, criteriaModel) {
      //Geral
      this.questionCriteriaList.push(criteriaModel)
      //In question
      this.questions.forEach((question, index) => {
        if (question.id === criteriaModel.question_id) {
          question.criteriaList.push(criteriaModel)
        }
      })

      this.forceRepaintCriteriaByQuestion(questionId)
    },
    clearFormCriteria(criteriaModel) {
      criteriaModel.id = null
      criteriaModel.question_id = null
      criteriaModel.title = ''
      criteriaModel.weight = ''
      criteriaModel.description = ''
    },
    forceCollapse(id) {
      const key = `collapse_${id}`
      const ref = this.$refs[key]
      const element = _.isArray(ref) ? ref[0] : ref

      if (element && 'changeHeight' in element) {
        setTimeout(() => {
          element.changeHeight()
          const extraction = parseInt(element.maxHeight.split('px')[0])
          element.maxHeight = `${(extraction + Math.floor(extraction * 0.3))}px`
        }, 200)
      }
    },
    forceRepaintCriteriaByQuestion(id) {
      this.keyToForceRepaint++
      this.forceCollapse(id)
    },
    editorTextIsOpen(id) {
      console.log(`ckEdditorIsOpen in config: ${id}`)
      this.forceCollapse(id)
    },
    scrollToBotton() {
      setTimeout(() => {
        window.scrollTo(0,
          window.document.documentElement.scrollTop + this.$root.$el.offsetHeight + 50)
      }, 200)
    },
    validateMaxWeight(questionId, criteriaModel) {
      console.log(`on weight updated:${  criteriaModel.weight}`)

      const weight = Number.parseInt(criteriaModel.weight)
      let total = 0
      if (this.allCriteria) { //For All
        this.criteriaList.forEach((criteria, index) => {
          //Alter in criteria just add
          if (criteria.id !== criteriaModel.id) {
            total = total + Number.parseInt(criteria.weight)
          }
        })
      } else { //By Question
        this.questions.forEach((question, index) => {
          if (question.id === questionId) {
            question.criteriaList.forEach((criteria, index) => {
              //Alter in criteria just add
              if (criteria.id !== criteriaModel.id) {
                total = total + Number.parseInt(criteria.weight)
              }
            })
          }
        })
      }
      console.log(total)
      if (total + weight > 100) {
        criteriaModel.weight = 100 - total
        this.notifyWarning(this.$vs, this.$t('questionnaire_corrector.msg_max_weight_reached', [100]), 3000)
      }
    }
  },
  beforeDestroy() {
    this.destroyChilds(this)
  }
}
</script>

<style lang="scss">
  .vs-collapse-item--header{
    font-size: 0.9rem !important;
  }
  .vs-collapse-item--content{
    height: auto !important;
  }
</style>
