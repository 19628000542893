var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { staticClass: "mb-4" },
        [
          _c(
            "vs-tabs",
            {
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c(
                "vs-tab",
                { attrs: { label: _vm.$t("questionnaire_corrector.config") } },
                [
                  _c("questionnaire-corrector-config", {
                    attrs: { id: _vm.id },
                  }),
                ],
                1
              ),
              _vm.isEdit()
                ? _c(
                    "vs-tab",
                    {
                      attrs: {
                        label: _vm.$t("questionnaire_corrector.teachers"),
                      },
                    },
                    [
                      _c("questionnaire-corrector-teacher", {
                        attrs: { id: _vm.id },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }