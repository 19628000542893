var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "vx-row mt-2" }, [
      _c("div", { staticClass: "vx-col sm:w-1/1 w-full mb-2" }, [
        _c(
          "div",
          { staticClass: "w-full" },
          [
            _c("select-suggestion", {
              ref: "select_questionnaire",
              class: _vm.questionnaire ? "flex-grow" : "flex-grow required",
              attrs: {
                max: 20,
                label: _vm.$t("Questionnaire"),
                column: "name,description",
                model: "ContentQuestionnaire",
                appendClearOption: true,
                placeholderText: "Digite o nome do questionário",
              },
              model: {
                value: _vm.questionnaire,
                callback: function ($$v) {
                  _vm.questionnaire = $$v
                },
                expression: "questionnaire",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("questionnaire_id"),
                    expression: "errors.has('questionnaire_id')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("questionnaire_id")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|numeric",
                  expression: "'required|numeric'",
                },
              ],
              attrs: {
                lang: "pt-BR",
                type: "number",
                label: _vm.$t("questionnaire_corrector.min_score"),
              },
              model: {
                value: _vm.model.min_score,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "min_score", $$v)
                },
                expression: "model.min_score",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full mt-2" },
          [
            _c("vs-textarea", {
              attrs: {
                id: "description",
                label: _vm.$t("questionnaire_corrector.description"),
              },
              model: {
                value: _vm.model.description,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "description", $$v)
                },
                expression: "model.description",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("description"),
                    expression: "errors.has('description')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("description")))]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm.isEdit()
      ? _c("div", [
          _c("div", { staticClass: "vx-row flex flex-row mb-2" }, [
            _c("div", { staticClass: "w-full pb-4" }, [
              _c("label", { staticClass: "block text-left font-medium" }, [
                _vm._v(
                  _vm._s(_vm.$t("questionnaire_corrector.evaluation.title"))
                ),
              ]),
              _c("hr", { staticClass: "border-faint-grey" }),
            ]),
            _c(
              "div",
              { staticClass: "w-full flex mb-2 ml-4" },
              [
                _c("label", { class: _vm.allCriteria ? "text-primary" : "" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("questionnaire_corrector.criteria-all-questions")
                    )
                  ),
                ]),
                _c("vs-switch", {
                  staticClass: "bg-primary mr-2 ml-2",
                  on: { input: _vm.updateGrid },
                  model: {
                    value: _vm.allCriteria,
                    callback: function ($$v) {
                      _vm.allCriteria = $$v
                    },
                    expression: "allCriteria",
                  },
                }),
                _c("label", { class: _vm.allCriteria ? "" : "text-primary" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "questionnaire_corrector.criteria-different-questions"
                      )
                    )
                  ),
                ]),
              ],
              1
            ),
          ]),
          _vm.allCriteria
            ? _c(
                "div",
                { staticClass: "grid grid-cols-1 gap-y-2" },
                [
                  _vm._l(_vm.criteriaList, function (criteria, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c("correction-criteria", {
                          attrs: {
                            model: criteria,
                            index: index,
                            showButtonDelete: true,
                          },
                          on: {
                            deleteCriteria: _vm.deleteCriteria,
                            validateWeight: _vm.validateMaxWeight,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticStyle: { "margin-left": "-10px" } },
                        [
                          _c(
                            "vs-divider",
                            {
                              staticClass: "mt-4",
                              attrs: { position: "left" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("questionnaire_corrector.add-criteria")
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("correction-criteria", {
                        attrs: { showIndex: false, showButtonAdd: true },
                        on: {
                          addCriteria: _vm.addCriteria,
                          complexEditorOpened: _vm.editorTextIsOpen,
                          validateWeight: _vm.validateMaxWeight,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "grid grid-cols-1 gap-y-2" },
            [
              !_vm.allCriteria
                ? _c(
                    "vs-collapse",
                    { attrs: { accordion: "", type: "border" } },
                    _vm._l(_vm.questions, function (question, index) {
                      return _c(
                        "vs-collapse-item",
                        {
                          key: "question_" + question.id,
                          ref: "collapse_" + question.id,
                          refInFor: true,
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _c(
                                "div",
                                { staticClass: "grid grid-cols-2 mb-2" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex justify-start" },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("questao-this-index", [
                                              index + 1,
                                            ])
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "flex justify-end" },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(_vm.$t("pontuacao-maxima")) +
                                            " : " +
                                            _vm._s(
                                              _vm.formatScore(
                                                question.data.score
                                              )
                                            )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "editor-content ck-content content-break-word ml-3 mr-20",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.shortTitle(question.data.title, 200)
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              key: _vm.keyToForceRepaint,
                              staticClass: "grid grid-cols-1 gap-y-1",
                            },
                            _vm._l(
                              question.criteriaList,
                              function (qCriteria, cIndex) {
                                return _c(
                                  "div",
                                  { key: "cIndex_" + index + "_" + cIndex },
                                  [
                                    _c("correction-criteria", {
                                      attrs: {
                                        model: qCriteria,
                                        questionId: qCriteria.question_id,
                                        index: cIndex,
                                        showButtonDelete: true,
                                      },
                                      on: {
                                        deleteCriteria: _vm.deleteCriteria,
                                        complexEditorOpened:
                                          _vm.editorTextIsOpen,
                                        validateWeight: _vm.validateMaxWeight,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                          _c("div", [
                            _c(
                              "div",
                              { staticStyle: { "margin-left": "-10px" } },
                              [
                                _c(
                                  "vs-divider",
                                  {
                                    staticClass: "mt-4",
                                    attrs: { position: "left" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "questionnaire_corrector.add-criteria"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("correction-criteria", {
                                  attrs: {
                                    showIndex: false,
                                    showButtonAdd: true,
                                    questionId: question.id,
                                  },
                                  on: {
                                    addCriteria: _vm.addCriteria,
                                    complexEditorOpened: _vm.editorTextIsOpen,
                                    validateWeight: _vm.validateMaxWeight,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "vx-row mb-2 mt-8" }, [
      _c(
        "div",
        { staticClass: "w-full flex gap-2 justify-end" },
        [
          !_vm.isEdit()
            ? _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "questionnaire_correctors.create",
                      expression: "'questionnaire_correctors.create'",
                    },
                  ],
                  attrs: { disabled: !_vm.validateForm },
                  on: { click: _vm.createOrUpdate },
                },
                [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
              )
            : _vm._e(),
          _vm.isEdit()
            ? _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "questionnaire_correctors.edit",
                      expression: "'questionnaire_correctors.edit'",
                    },
                  ],
                  attrs: { disabled: !_vm.validateForm },
                  on: { click: _vm.createOrUpdate },
                },
                [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
              )
            : _vm._e(),
          _c(
            "vs-button",
            { attrs: { type: "border" }, on: { click: _vm.goBack } },
            [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }